export default function AnimatedName({ color = "#dadada" }) {
  return (
    <>
      <svg
        className="AnimatedName"
        width="1805"
        height="1114"
        viewBox="0 0 1805 1114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g id="clips">
          <clipPath id="clip-c">
            <path
              d="M248 208.5L244 216L235 219L228.5 219.5L224 215L222.5 209L221.5 203.5L220 198L214 191L209.5 185.5L198.5 176.5L190.5 169.5L180 160.5L172.5 154.5L160 150.5L142.5 148.5L122 147L108.5 148.5L96 155.5L77.5 167.5L64 179.5L58 193L50.5 211.5L45.5 226L39 245.5L33.5 265.5L30.5 281.5L27 299.5L25 318L24 335L25 344.5L27 360L29 373.5L33.5 385L42 399L49 409.5L58.5 418.5L70 427L86 435.5L99.5 436.5L114.5 437.5L131 435.5L144.5 432L160 426.5L173.5 420L186.5 414L198.5 406L209 398L216.5 392.5L228 380.5L236.5 370.5L244.5 360L251 350L259 337.5L265 323L271 307L276 295L280.5 281L285 267.5L290.5 255.5L294 243L296.5 226L300 211L304.5 196.5L306 182.5L308 168.5L310 155.5L312.5 133.5L314 120.5L315 101.5V88.5L313.5 70L312 55.5L309.5 35L304.5 7L309.5 6H322L325 8.5L328.5 35L331 51.5L332.5 74.5V96L331 119L330 137L327.5 160.5L325 180.5L322 202.5L319 221.5L313.5 247.5L307 270.5L300 290L292.5 309.5L286.5 325.5L279 343.5L270.5 359.5L260.5 377.5L249 392.5L234 406L218 416L197 431L177.5 440.5L154.5 450L138 457L114.5 461L94.5 460L77.5 458.5L58.5 450L45.5 441.5L34.5 430.5L22.5 416L12.5 404L6.5 390L1 368L0 344V321L3 302.5L6.5 279L12 254.5L19.5 231.5L28 206.5L39.5 182.5L53 163L68.5 145.5L84.5 135L103.5 126.5L127.5 123.5L154.5 126.5L180 133L203.5 145.5L222.5 159.5L235.5 177L244 195.5L248 208.5Z"
              fill="black"
            />
          </clipPath>
          <clipPath id="clip-h">
            <path
              d="M294.5 12L303 7L310 8.5L312.5 16.5L315 40L317 61L320.5 88.5L323.5 109.5L325.5 137.5L328 175L329 194.5V217.5L333 244.5L335.5 270L339.5 291.5L343 309L349 333.5L356 360.5L363 382.5L370 402L378.5 416L384.5 430.5L388.5 431.5L392.5 430.5L394.5 423L399.5 413.5L406.5 402L413 386.5L418.5 372L425 359L429.5 345L433.5 332L442 311.5L449 299.5L454.5 285.5L461.5 266.5L470 255L478.5 249L488.5 250.5L494.5 257.5L502.5 272L509 284.5L518 305.5L527.5 324L538.5 344L549.5 364L563 379L579 396.5L595 409L612 417L627.5 419H649L664.5 412.5L684 404.5L695 396.5L707.5 386L720 376.5L738 360L749 349L760 336.5L771 322.5L776 307.5V294.5L771 283L767 276L762 271L755 265.5L772 240.5L776 244L780 249L784.5 256L792.5 276L795.5 290V299L791 314.5L782.5 334.5L771 347L757.5 361L744 374.5L727.5 389L711 401.5L693.5 416L675.5 427L653.5 434L636 436.5L619 435L601 431.5L589 429L575.5 422.5L563 413.5L551 400.5L541 390.5L532 377.5L522 364L514 352.5L505 336.5L498 324L492 311.5L487 297.5L484 292H480.5L477 299.5L472 310.5L466 324L458.5 338.5L451.5 360.5L442.5 384.5L435 400.5L423.5 425L415 441.5L405.5 457L398.5 463.5L391 465L376.5 458L367 450L358.5 438.5L354 425L347 413L340.5 395L332.5 372L328.5 354L323 336.5L319.5 320.5L316 301.5L314 287L311 262.5L308 247.5L307 235.5L305 219.5L303 199V187.5V172.5V158L301 143V132L300 107.5L296.5 90.5V80L294.5 68.5L292.5 56V44L291 34V22L294.5 12Z"
              fill="#C4C4C4"
              stroke="black"
            />
          </clipPath>
          <clipPath id="clip-ar">
            <path
              d="M766.5 235L772 240.5L755 265.5L753 264L750 261.5L744.5 257L739 256H729.5L713.5 256.5L703 261L693 267L684.5 278L672.5 296L665.5 316.5L661 334L659.5 351.5V367.5L661.5 377L667 380H672L680.5 371L692 360L700.5 347L711 333.5L718 324.5L725.5 316.5L733.5 304.5L741 293L749.5 283.5L753 276.5L758 268L763 261L770 250L775.5 241.5L779 233.5L788 229.5H795.5L801.5 234.5L807 242L810.5 254.5L816 270L822.5 284.5L825 300.5L829 316.5L836 339.5L841.5 360.5L846 376L850.5 388.5L853 391.5L857 390L860 384.5L860.5 375V365L863 356.5L864.5 340L866.5 327.5L869 314L872 300.5L876.5 288.5L883.5 268L888.5 257L896.5 251L905.5 251.5L909 257L916.5 269L922.5 287.5L927.5 306L932.5 326L937 346.5L943 363.5L950.5 377.5L957.5 390L963 392.5L967 390.5L973.5 384L980.5 372.5L986.5 364.5L992.5 355.5L1000 345L1006 335L1012 326L1017 316L1025.5 303L1030 293.5L1039 279L1047.5 266.5L1053.5 256.5L1059 250H1065.5L1070 252L1071.5 256.5L1069.5 265.5L1065.5 272L1061.5 280L1056.5 295.5L1052 303L1047.5 312.5L1037.5 326.5L1032.5 335L1028.5 345.5L1021.5 359L1015.5 368L1007 379.5L999 390L987 404L976.5 416L969 426.5L961 430L952 428.5L943.5 421.5L938.5 413.5L932.5 402L927.5 390L920.5 377L915.5 361.5L911 351L908 340L906.5 326L904 313L901.5 300L900 294L898.5 296.5L895 313L893.5 325L888.5 343L887 356.5L885 373L880.5 393.5L876.5 407.5L870 416L859.5 424.5L851.5 425.5L840.5 417L833 408.5L829 403L825.5 390L821 380L816 364L810.5 348.5L805.5 329.5L803.5 313L800.5 298.5L797 285.5L792 275.5H785.5L780 279.5L774.5 287L768.5 297L764.5 302.5L754.5 316L743.5 328L737.5 338L726.5 351.5L715 365.5L705.5 375.5L696.5 384.5L685 394.5L672.5 402.5L664 404.5L653 401.5L641.5 394.5L634.5 388L633 371.5V358.5L634.5 341.5L638.5 325.5L641.5 309.5L644.5 295L654.5 274.5L663 261.5L672.5 249.5L683 241.5L696.5 233L706 228.5L718.5 225L733 223L747 225L761 231.5L766.5 235Z"
              fill="#C4C4C4"
              stroke="black"
            />
          </clipPath>
          <clipPath id="clip-l">
            <path
              d="M1090.5 65L1101 60.5L1109 62L1116.5 65L1120 70.5L1122 81.5V90L1115.5 102L1119 122L1120 141L1122 157L1124 181.5V204L1127 223L1128 243L1130 260.5L1133.5 283.5V301L1136 321.5L1137.5 341.5L1139 354.5L1140 368.5L1142 380.5L1143 397V403.5L1145.5 414V425L1147.5 437.5V447L1149 461.5L1152 478.5L1156 500L1161 508.5L1162.5 522L1160 527L1156 528L1145.5 520L1143 508.5L1135 498.5L1130.5 484.5L1127 473L1125 459.5L1123 445L1119 428V418.5V399L1117 382.5L1114.5 363.5L1113 347L1110 331L1109 319L1107 293.5L1104.5 272V250L1102 226.5L1099.5 204L1097.5 181.5L1095 167.5V149.5L1092 132.5L1090.5 110.5L1089 100.5L1086.5 87.5V72.5L1090.5 65Z"
              stroke="black"
            />
          </clipPath>
          <clipPath id="clip-es-start">
            <path
              d="M1244 297.5L1250.5 295L1258.5 320.5L1247.5 324L1235.5 328.5L1222.5 336.5L1215 340L1205.5 347L1196 356L1188 366.5L1182 375L1172.5 381H1160.5L1153 377L1150 368.5L1153 360L1163 349.5L1171 342L1181 333L1192 324L1203 315.5L1219 308L1230.5 302.5L1244 297.5Z"
              fill="#C4C4C4"
              stroke="black"
            />
          </clipPath>
          <clipPath id="clip-es-end">
            <path
              d="M1257.5 292.5L1251 294.5L1258.5 320.552L1267 318.5L1279 313L1287.5 303L1294.5 294.5L1298 281V263.5L1296.5 249.5L1292 238.5L1282.5 229L1274.5 223.5L1263.5 222L1250 223.5L1241 228.5L1228 238.5L1221.5 251.5L1219 269L1217.5 287V303L1220.5 314L1223.5 328L1229 341L1234 351.5L1246.5 359L1258.5 363.5L1272.5 360.5L1290 353.5L1302.5 347.5L1319 337L1330.5 329.5L1342 322L1351.5 316L1363 311.5L1373.5 303L1391 299L1402 294.5L1415 291L1430 288.5H1439L1450.5 291L1455 298L1454.5 306L1450.5 314L1441.5 320L1434.5 328L1424.5 333.5L1413 338.5L1400.5 341L1387 344H1382L1381 337V330.5L1367 328L1365.5 332L1364.5 343L1367 351.5L1376 360.5L1387 365.5L1405 367.5H1421.5L1439 363.5L1455.5 357L1470 343L1479.5 328L1485 312.5L1484.5 297L1479.5 282L1466.5 271L1457 265.5H1441.5L1423 267L1407.5 269L1392 273L1378.5 278L1359 287L1343.5 288.5L1334 294.5L1325.5 303L1311 311.5L1300 317.5L1290 326.5L1281.5 333.5L1269.5 337.5L1258.5 336L1250 330.5L1246.5 322L1244.5 308L1241 294.5V288.5V278L1243 269L1248.5 258L1255 251L1263.5 249.5L1266 255L1270.5 265V278L1266 287L1257.5 292.5Z"
              fill="#C4C4C4"
              stroke="black"
            />
          </clipPath>

          <clipPath id="clip-h1">
            <path
              d="M492.5 451.5L486 457.5L484.5 470V485.5V502.5V519L483.5 531.5V544.5V559.5L485 578.5L486 595.5V612V632.5L487.5 669.5L488.5 678.5L489.5 699V720V749.5L490.5 770.5L492.5 789.5L494.5 805L497 821L498 833L499 855L501.5 886.5L506.5 909.5L511 942.5L515.5 963.5L523.5 981.5L536 992.5L554 992L561.5 981.5L564 970L547 974L538.5 943L536 925L529.5 890.5L526.5 859.5L523 823.5L518 786L515 748.5L513.5 705L511.5 663.5L509.5 623.5V587L508 545.5L507 506.5L508.5 463L507 453.5L500 451.5H493"
              fill="black"
            />
          </clipPath>

          <clipPath id="clip-h2">
            <path
              d="M666.5 481.5L674 472.5H688L694.5 477.5L699 490.5L703.5 517.5L707 545L710 583L716 635L723.5 692.5L725.5 718.5L731.5 757.5L735.5 784L744.5 824.5L748.5 862L753 883.5L760 928L763.5 961L767 992L770 1032.5L774.5 1058L778 1097V1111L767 1113L748.5 1109L739.5 1095L729.5 1081L724.5 1068L713.5 1047L703.5 1027L688 1004.5L673 984.5L663.5 970.5L651 950L637.5 933L625 909.5L610 887.5L597 869L582 845.5L563.5 821.5L539.5 786.5L527 769.5L507 746L486 724.5L473 709L457 693.5L433 681L419 676L398.5 673.5H379.5L369 676L356.5 679.5L344 687L331.5 696L325 693.5L319 685L321 681L325 676L336 670.5L346 664.5L360.5 660.5L384.5 657L403 658L420.5 663L435.5 668L451.5 677.5L476 697.5L490.5 709L511 729L523 746L534.5 760.5L543 771L554 786.5L571.5 810L586.5 829.5L601.5 854.5L623.5 883.5L634.5 905L653 933L668.5 954L684 977L709.5 1010.5L736.5 1047L745.5 1058V1052V1038L742.5 1020.5L739.5 992L735.5 952L729.5 910.5L723.5 875.5L719.5 857L716 826L713 799L706.5 763.5L701.5 732.5L697 694.5L694.5 663.5L689.5 626.5L688 604L686.5 572L679.5 541.5L675.5 517.5L666.5 523L658.5 524.5L661 514.5L666.5 499V481.5Z"
              fill="black"
            />
          </clipPath>

          <clipPath id="clip-h3">
            <path
              d="M919 698L930 686.5L932 683.5L930.5 681L926.5 676L919 672.5L911.5 674.5L895 678.5L883.5 680L866 682L851 683L835 682L800.5 677L785 674.5L717.5 669L681.5 664L638.5 662.5H588L550 664L519 667L475 670.5L408 674.5L373 678.5L340.5 680.5L319 685V698L321.5 709L327.5 711.5L355.5 707.5L367 704.5L397.5 702L423 700L447 698L466 696L494.5 693.5L517 689.5H547.5H575.5L604 688H632L655 689.5L680 691.5L707.5 693.5H733L764.5 696.5H772L788.5 699.5L800.5 703L815.5 704.5L836.5 707H852H870.5L888 704.5L907.5 701L919 698Z"
              fill="black"
            />
          </clipPath>

          <clipPath id="clip-arr">
            <path
              d="M900.5 681.5L928.5 684L934 688.5V697L932 705L925.5 711.5L919.5 713.5L907 715H893L874 717.5L859.5 721.5L848 729.5L834.5 742L824 756L815 772.5L810.5 790.5V809.5L815 827L821.5 835.5L831.5 839.5L842 835.5L848 827L856 814.5L865.5 798.5L874 783L887.5 769L895 757.5L905.5 743.5L915 731L921.5 719L929.5 710L939 703H947L950.5 710L957 719L963.5 737.5L972.5 761.5L980.5 785L987 802L994.5 814.5L1004.5 817L1009 812.5L1020.5 802L1031 789.5L1038 777L1048 764L1054 751L1063.5 737.5L1071 725L1076.5 718L1081.5 708L1090 703L1100 704.5L1105.5 710.5L1112.5 719L1124 740L1137 759.5L1150 774.5L1157.5 784.5L1168 787.5H1179L1189 781L1200.5 774.5L1213 764L1224.5 753.5L1235 740L1243 728L1255 714L1261.5 709L1274.5 712.5L1278.5 723.5L1283.5 733.5L1290 746.5L1299.5 762L1311 778L1319.5 791.5L1329 797H1334.5L1338.5 790.5L1344 781L1354.5 770L1360 764L1368 770L1366.5 775L1363 783.5L1356.5 797L1350.5 810.5L1344 819.5L1334.5 821L1319.5 817.5L1304.5 805L1289 797L1280.5 787.5L1273 778L1268.5 766.5L1261.5 748L1255 754.5L1244 767.5L1232.5 776L1218.5 790.5L1199.5 801.5L1185.5 813L1166.5 814.5L1150 811.5L1134.5 799L1120.5 782.5L1112.5 769L1104 754.5L1094 740.5L1091.5 743.5L1082.5 754.5L1075 765.5L1063.5 786L1046.5 808L1030 832L1011 845.5L996.5 848.5L981.5 839L969.5 827L959.5 811.5L954.5 793L947 769L942 751.5L937.5 743.5L931 749.5L920.5 761.5L910 776.5L900 792L887.5 811.5L874 829L861 848.5L849 860L833 863L817 862L803 850L794 837.5L789 827.5L788 803.5L790 784L792.5 767L797.5 751.5L805.5 737.5L813.5 724.5L829 710L844.5 697L859.5 688.5L884 681.5H900.5Z"
              stroke="black"
            />
          </clipPath>

          <clipPath id="clip-i">
            <path
              d="M1383 638L1391.5 624.5H1399L1406.5 635L1408.5 650.5V672L1412.5 694L1419.5 718L1422 744.5L1428.5 770.5L1432 792.5L1439.5 807L1435 813.5L1428.5 823.5L1419.5 821.5L1406.5 807L1402 789L1395.5 766.5L1391.5 739.5C1389.33 730.167 1385 711.1 1385 709.5C1385 707.9 1381.67 695.167 1380 689L1375 662L1383 638Z"
              fill="black"
            />
          </clipPath>

          <clipPath id="clip-i-dot">
            <path
              d="M1341.5 560L1356 557L1362.5 562.5L1375 567.5L1386.5 578.5L1399.5 596L1396.5 602L1389.5 609L1381.5 602L1370 589L1359.5 581H1341.5L1334 570L1341.5 560Z"
              fill="black"
            />
          </clipPath>

          <clipPath id="clip-es">
            <path
              d="M1542 734.5L1519.5 750L1511.5 760.5L1508 782.5L1513.5 800H1527L1546 787.5L1569 772L1603 760.5L1642.5 750L1677.5 744L1701.5 736.5L1728 732L1759.5 729H1783L1796.5 732L1804 740V758.5L1793 774L1771.5 785.5L1746.5 791.5L1714.5 800H1690L1683 797V787.5L1699 778L1717.5 766L1733 758.5H1717.5H1699L1679.5 768.5L1648 774L1627 780.5L1605 787.5L1579 800L1556 812.5L1534.5 823.5L1505.5 820L1490.5 807.5L1485 791.5L1487 768.5L1494 734.5L1501 721L1513.5 696L1530.5 672.5L1546 653.5L1569 639L1587.5 635L1599.5 637.5L1608.5 653.5L1599.5 675.5L1584 696L1564 713L1542 734.5Z"
              stroke="black"
            />
          </clipPath>
        </g>
        <g id="strokes">
          <path
            className="stroke stroke-c"
            d="M242 230.5L234 206.5L224.5 183.5L207.5 165.5L185.5 150L161.5 141.5L131.5 136.5H109.5L87.5 146L72.5 158L61.5 169L50.5 183.5L41.5 202.5L34 221L28 241L23 261L18 282L13.5 305L11 333L12 352L15.5 373.5L22 391.5L36.5 415L49.5 426.5L75 446L95.5 449L138.5 446L172 433L192 420.5L213.5 405.5L233.5 390.5L250 370.5L265.5 345L278 317.5L286.5 297L294 273L303 246L309 224.5L314.5 190L318.5 162.5L321.5 131L323.5 102V70.5L320.5 43.5L317 18L313.5 1"
            fill="none"
            clipPath="url(#clip-c)"
            strokeWidth="30"
          />
          <path
            className="stroke stroke-h"
            d="M317.5 13L308.5 14.5L301 17.5L301.5 28.5L303.5 44.5L305.5 59L308.5 88L311 103.5L313.5 119.5L315 144.5V170L316.5 191L317.5 210L319 231.5L322 255.5L325 271.5L329.5 302L334 320.5L340.5 347L345 369L354.5 397.5L364 417L376 439L386.5 447L393.5 449.5L399.5 445L408.5 425L415 409.5L427 386L434.5 367.5L443.5 343.5L451.5 321L458 309L468 287L474 275.5L479 267.5H483L490.5 273.5L497.5 288.5L504 306.5L510.5 319.5L517.5 331.5L524 344.5L530.5 356L538.5 366.5L548 379.5L556.5 390.5L565 398.5L573.5 405.5L582.5 412L592.5 417.5L606 422.5L617.5 425H630H643L659 422.5L670.5 418.5L681 414L689.5 409L701.5 400L712.5 392L720 384.5L732 375L741.5 366L750.5 356.5L759.5 347.5L766.5 339L774.5 328.5L780 318.5L785 305L786 295.5L785 284L780 271L769.5 257.5L759.5 246.5"
            fill="none"
            clipPath="url(#clip-h)"
            strokeWidth="30"
            stroke={color}
          />
          <path
            className="stroke stroke-ar"
            d="M767 257.5L758.5 249L742 242.5L725.5 239.5L710 241.5L691 250.5L680.5 260.5L673 271L666.5 280.5L658.5 295L653 309.5L648.5 328L646.5 342.5L645.5 359.5V373L648.5 382.5L653 388.5L661 391.5H673L683.5 384L696.5 368.5L707 356.5L716 345.5L728.5 327.5L736 317.5L743.5 308.5L751.5 298L758.5 288.5L767 276.5L773 268.5L778 261.5L784 254.5L789 246L798.5 257.5L803.5 269.5L808.5 282.5L810.5 294L814.5 309L818 326.5L821.5 341.5L826.5 356L830 367.5L834 378L837.5 389L842 397L849.5 405.5L857 407.5L864.5 402.5L869 387.5L872 366L875.5 341L879.5 326.5L883.5 304.5L889 287L894 273L897 265.5L902.5 267.5L908 282.5L912 301L918 325.5L921 339L925.5 353L932 369L939.5 386.5L946 398.5L952.5 405.5L957 411L964.5 409L974 399.5L985.5 385L994 373L1002.5 359L1012.5 345.5L1021.5 330.5L1030 315L1038.5 301L1046 286L1052 275.5L1057.5 266L1062.5 258L1075 238.5"
            fill="none"
            clipPath="url(#clip-ar)"
            strokeWidth="30"
            stroke={color}
          />
          <path
            className="stroke stroke-l"
            d="M1106 56L1102 83V105.5L1104.5 125L1107.5 149.5L1111 187L1113 208.5L1116 251.5L1119.5 281.5L1124.5 331.5L1127 358L1130 398.5L1134 441.5L1138 471L1144.5 496.5L1155 519L1163.5 538"
            fill="none"
            clipPath="url(#clip-l)"
            strokeWidth="30"
            stroke={color}
          />
          <path
            className="stroke stroke-es-start"
            d="M1149.5 384.5L1159 373L1170.5 360L1180.5 349.5L1191.5 339L1200 332.5L1210 327L1223.5 320L1234.5 315L1245 310.5L1252 308L1259.5 305"
            fill="none"
            clipPath="url(#clip-es-start)"
            strokeWidth="30"
            stroke={color}
          />
          <path
            className="stroke stroke-es-end"
            d="M1251 308.5L1265 304L1276.5 296L1281.5 281.5L1283 265L1280.5 246L1265 235.5L1249 237L1236.5 250L1231 270.5L1229.5 292L1232.5 312.5L1238 330L1249 342.5L1265 350L1283 346L1300 334L1321 321L1334 312.5L1351 305L1374.5 292L1397.5 283L1419 278.5L1440.5 276.5L1460 281.5L1468 292L1469.5 309.5L1465.5 325L1449.5 340.5L1429.5 350.5L1412.5 353H1391.5L1380 350.5L1374.5 345.5L1372 337.5L1373.5 327L1376.5 321"
            fill="none"
            clipPath="url(#clip-es-end)"
            strokeWidth="30"
            stroke={color}
          />

          <path
            className="stroke stroke-h1"
            clipPath="url(#clip-h1)"
            d="M496 443V485.5V522L494.5 549.5L497.5 590.5V621.5L499 676L502.5 713V775.5L508 809L513.5 858L517.5 894.5L526 944L531 965.5L537.5 980.5L547 986L571.5 962.5"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
          <path
            className="stroke stroke-h2"
            clipPath="url(#clip-h2)"
            d="M649.5 534L673 506L684 490L696.5 552L700 590L705 651.5L710 694L717 757.5L726.5 801.5L733 851.5L742 896L748 946L753 990.5L758 1048L759 1073L761.5 1098.5L733 1061L708 1021L671 968.5L634.5 915L602 864.5L585.5 839.5L556 797.5L521 752.5L480 707.5L450 682.5L415 666.5L389.5 663.5L367 666.5L350.5 672L333.5 682.5L330 685.5L305.5 705"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
          <path
            className="stroke stroke-h3"
            clipPath="url(#clip-h3)"
            d="M295.5 702.5L327.5 697.5L370 691L413 688L468 683L517 679.5L572.5 677.5L636.5 675L665 676L751 682.5L794.5 688L825.5 692L864 694.5L893.5 692L915.5 682.5L936 672"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
          <path
            className="stroke stroke-arr"
            clipPath="url(#clip-arr)"
            d="M943.5 699H910.5L884.5 700L859.5 703.5L845.5 714L827 729.5L814 744L805 762.5L801 780.5L797.5 806.5L801 825L809 838L820.5 846.5L833 849L846 847.5L855 838L867.5 821L876.5 806.5L886.5 788L897 774L906 760.5L914.5 750L925.5 734.5L933.5 724L940.5 717L953.5 744L958 759.5L964.5 777L971 795.5L976 809L983 819.5L992.5 827.5L1004.5 831.5L1022 822L1035 807.5L1047.5 788L1060.5 768.5L1069 752.5L1081 738.5L1091.5 720.5L1101.5 726.5L1116 750L1128 774L1142.5 788L1159.5 800.5L1175.5 802.5L1193.5 795.5L1219.5 772L1240.5 753.5L1252.5 737L1262 726.5L1275 743L1287 765L1297.5 782.5L1309.5 795.5L1321.5 804L1338 809.5L1346 804L1355 785.5L1363 772L1370.5 757.5"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />

          <path
            className="stroke stroke-i"
            clipPath="url(#clip-i)"
            d="M1394.5 616L1390 640V662L1392 685L1397.5 698L1402 728.5L1407 744.5L1411 765.5L1416 788.5L1421 800.5L1428.5 813.5L1439 820"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
          <path
            className="stroke stroke-i-dot"
            clipPath="url(#clip-i-dot)"
            d="M1324.5 569.5H1346.5H1359L1370.5 576L1379.5 585L1392 596.5L1400.5 606.5"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
          <path
            className="stroke stroke-es"
            clipPath="url(#clip-es)"
            d="M1479.5 745.5L1528 731L1553 710.5L1577 687L1591 660V648.5H1577L1553 663.5L1536 687L1520.5 707L1508.5 729.5L1501.5 750.5L1497.5 774.5V793.5L1504.5 804L1518.5 810L1547 805.5L1568.5 791L1591 779L1615 771.5L1641.5 762.5L1669.5 756.5L1702.5 748.5L1726 745L1764.5 743.5L1776 746L1782.5 756.5L1766 773L1742 777.5L1720 788.5L1699.5 792.5H1684L1660.5 796"
            stroke={color}
            strokeWidth="30"
            fill="none"
          />
        </g>
      </svg>

      <h1 className="visually-hidden" id="title">
        Charles Harries
      </h1>
    </>
  );
}
